import * as React from "react";
import {
  Flex,
  Box,
  VStack,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useBreakpointValue,
  Button,
} from "@chakra-ui/react";
import { useWeb3Context } from "../../hooks/Web3Provider";

import AddFundingSourceForm from "../../components/FundingFactory/AddFundingSource";
import CoordinatorQuitForm from "../../components/FundingFactory/CoordinatorQuit";
import CancelCurrentRoundForm from "../../components/FundingFactory/CancelCurrentRound";
import DeployNewRoundForm from "../../components/FundingFactory/DeployNewRound";
import RemoveFundingSourceForm from "../../components/FundingFactory/RemoveFundingSource";
import SetCoordinatorForm from "../../components/FundingFactory/SetCoordinator";
import SetMaciParametersForm from "../../components/FundingFactory/SetMaciParameters";
import SetRecipientRegistryForm from "../../components/FundingFactory/SetRecipientRegistry";
import SetTokenForm from "../../components/FundingFactory/SetToken";
import SetUserRegistryForm from "../../components/FundingFactory/SetUserRegistry";
import TransferMatchingFundsForm from "../../components/FundingFactory/TransferMatchingFunds";

const FlexBox = ({ children, flexGrow = 1, alignItems = "center", justifyContent = "center", ...rest }) => {
  return (
    <Box display="flex" flexGrow={flexGrow} alignItems={alignItems} justifyContent={justifyContent} {...rest}>
      {children}
    </Box>
  );
};
export const ConfigureFundingFactoryDapp = () => {
  const { web3Start, web3Connect, web3Logout } = useWeb3Context();
  return (
    <VStack>
      <Tabs width="100%" isLazy>
        <Flex direction="row" pl="58px">
          <FlexBox
            hidden={useBreakpointValue({ base: true, md: true, lg: false })}
            alignItems="top"
            justifyContent="left"
            flexShrink={0}>
            <TabList mt="30px" border="none">
              <VStack alignItems="start" spacing={-3} fontFamily="Helvetica" letterSpacing="-0.6px" color="gray.400">
                <Button
                  sx={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    border: "none",
                    letterSpacing: "-1px",
                    background: "none",
                    ":focus": { boxShadow: "none", fontSize: "14px", fontWeight: "bold" },
                  }}>
                  Funding Factory
                </Button>
                <Tab
                  sx={{
                    fontSize: "14px",
                    border: "none",
                    letterSpacing: "-1px",
                    ":focus": { boxShadow: "none", fontSize: "14px", fontWeight: "bold" },
                  }}>
                  Set User Registry
                </Tab>
                <Tab
                  sx={{
                    fontSize: "14px",
                    border: "none",
                    letterSpacing: "-1px",
                    ":focus": { boxShadow: "none", fontSize: "14px", fontWeight: "bold" },
                  }}>
                  Set Recipient Registry
                </Tab>
                <Tab
                  sx={{
                    fontSize: "14px",
                    border: "none",
                    letterSpacing: "-1px",
                    ":focus": { boxShadow: "none", fontSize: "14px", fontWeight: "bold" },
                  }}>
                  Set Token
                </Tab>
                <Tab
                  sx={{
                    fontSize: "14px",
                    border: "none",
                    letterSpacing: "-1px",
                    ":focus": { boxShadow: "none", fontSize: "14px", fontWeight: "bold" },
                  }}>
                  Add Funding Source
                </Tab>
                <Tab
                  sx={{
                    fontSize: "14px",
                    border: "none",
                    letterSpacing: "-1px",
                    ":focus": { boxShadow: "none", fontSize: "14px", fontWeight: "bold" },
                  }}>
                  Set Maci Parameters
                </Tab>
                <Tab
                  sx={{
                    fontSize: "14px",
                    border: "none",
                    letterSpacing: "-1px",
                    ":focus": { boxShadow: "none", fontSize: "14px", fontWeight: "bold" },
                  }}>
                  Set Coordinator
                </Tab>
                <Tab
                  sx={{
                    fontSize: "14px",
                    border: "none",
                    letterSpacing: "-1px",
                    ":focus": { boxShadow: "none", fontSize: "14px", fontWeight: "bold" },
                  }}>
                  Deploy New Round
                </Tab>
                <Tab
                  sx={{
                    fontSize: "14px",
                    border: "none",
                    letterSpacing: "-1px",
                    ":focus": { boxShadow: "none", fontSize: "14px", fontWeight: "bold" },
                  }}>
                  Transfer Matching Funds
                </Tab>
                <Tab
                  sx={{
                    fontSize: "14px",
                    border: "none",
                    letterSpacing: "-1px",
                    ":focus": { boxShadow: "none", fontSize: "14px", fontWeight: "bold" },
                  }}>
                  Cancel Current Round
                </Tab>
                <Tab
                  sx={{
                    fontSize: "14px",
                    border: "none",
                    letterSpacing: "-1px",
                    ":focus": { boxShadow: "none", fontSize: "14px", fontWeight: "bold" },
                  }}>
                  Remove Funding Source
                </Tab>
                <Tab
                  sx={{
                    fontSize: "14px",
                    border: "none",
                    letterSpacing: "-1px",
                    ":focus": { boxShadow: "none", fontSize: "14px", fontWeight: "bold" },
                  }}>
                  Coordinator Quit
                </Tab>
              </VStack>
            </TabList>
          </FlexBox>
          <Box flexGrow={1}>
            {" "}
            <LoginArea />
          </Box>
        </Flex>
      </Tabs>
    </VStack>
  );
};

const LoginArea = () => {
  return (
    <Flex align="center" justifyContent="flex-start">
      <Box p={4}>
        <TabPanels>
          <TabPanel>
            <SetUserRegistryForm />
          </TabPanel>
          <TabPanel>
            <SetRecipientRegistryForm />
          </TabPanel>
          <TabPanel>
            <SetTokenForm />
          </TabPanel>
          <TabPanel>
            <AddFundingSourceForm />
          </TabPanel>
          <TabPanel>
            <SetMaciParametersForm />
          </TabPanel>
          <TabPanel>
            <SetCoordinatorForm />
          </TabPanel>
          <TabPanel>
            <DeployNewRoundForm />
          </TabPanel>
          <TabPanel>
            <TransferMatchingFundsForm />
          </TabPanel>
          <TabPanel>
            <CancelCurrentRoundForm />
          </TabPanel>
          <TabPanel>
            <RemoveFundingSourceForm />
          </TabPanel>
          <TabPanel>
            <CoordinatorQuitForm />
          </TabPanel>
        </TabPanels>
      </Box>
    </Flex>
  );
};
